<template>
  <div :style="boxStyle" @click="tapEarth">
    <div class="earth-xin" :style="xinStyle">
      <div class="earth-xin-top"></div>
    </div>
    <div
      class="earth-quan"
      v-for="n in quanNum"
      :key="'num_' + n"
      :style="quanStyle[n - 1]"
    ></div>
    <div
      class="earth-dot"
      v-for="(item, index) in dots_data"
      :key="index"
      :style="item.style"
      @click.stop="tapDots(item)"
    >
      <span class="earth-text">{{ item.name }}</span>
    </div>
  </div>
</template>

<script>
import { objectToStr } from "@/utils";

export default {
  name: "Earth",
  props: {
    dots: {
      type: Array,
      default() {
        return [];
      },
    },
    quanNum: {
      type: Number,
      default: 3,
    },
    boxWidth: {
      type: Number,
      default: 375,
    },
  },
  data() {
    return {
      dots_data: [],
      boxStyle: "",
      xinStyle: "",
      timer: null,
    };
  },
  computed: {
    quanRect() {
      let w = this.boxWidth*1.2;
      let n = this.quanNum;
      let space_w = Math.floor(w / 8) / (n - 1);
      let arr = [];
      for (let i = 0; i < n; i++) {
        let left = space_w * (i + 1);
        let quan_w = w - 2 * left;
        let quan_h = Math.floor(quan_w / 2);
        let top = (w - quan_h) / 2;
        arr.push({
          width: quan_w,
          height: quan_h,
          top: top,
          left,
        });
      }
      return arr;
    },
    quanStyle() {
      return this.quanRect.map((item) => {
        return objectToStr(
          {
            width: item.width + "px",
            height: item.height + "px",
            top: item.top + "px",
            left: item.left + "px",
          },
          "style"
        );
      });
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      let w = this.boxWidth*1.2;
      this.boxStyle = objectToStr(
        {
          width: w + "px",
          height: w + "px",
        },
        "style"
      );

      let xw = Math.floor(w / 2);
      let cha = (w - xw) / 2;
      this.xinStyle = objectToStr(
        {
          width: xw + "px",
          height: xw + "px",
          top: cha + "px",
          left: cha + "px",
        },
        "style"
      );

      if (!this.dots || !this.dots.length) return;
      this.initDots(this.dots);
    },
    initDots(data) {
      this.dots_data = data.map((item, index) => {
        let quan = this.quanRect[index % this.quanNum];
        let a = quan.width / 2;
        let b = quan.height / 2;
        let deg = Math.floor(360 / data.length) * index;
        item.style = this.getDotStyle(a, b, deg);
        item.a = a;
        item.b = b;
        item.deg = deg;
        return item;
      });

      this.earthPlay();
    },
    earthPause() {
      if (!this.dots || !this.dots.length || !this.timer) return;
      clearInterval(this.timer);
      this.timer = null;
    },
    earthPlay() {
      if (!this.dots || !this.dots.length) return;
      this.timer = setInterval(() => {
        this.dots_data = this.dots_data.map((item) => {
          item.deg = (item.deg + 6) % 360;
          item.style = this.getDotStyle(item.a, item.b, item.deg);
          return item;
        });
      }, 500);
    },
    tapEarth() {
      if (this.timer) {
        this.earthPause();
      } else {
        this.earthPlay();
      }
    },
    tapDots(item) {
      this.$jump(item.url)
    },
    getDotStyle(a, b, deg) {
      let w = this.boxWidth*1.2;
      let coord = this.getCoord(a, b, deg);
      let top = w / 2 + coord[1] - 5;
      let left = w / 2 + coord[0] - 5;
      return objectToStr(
        {
          top: top + "px",
          left: left + "px",
        },
        "style"
      );
    },
    // 获取椭圆上的初始坐标值，y取相反值，方便计算
    getCoord(a, b, deg) {
      if (deg == 0 || deg == 360) return [a, 0];
      if (deg == 180) return [-a, 0];
      if (deg == 90) return [0, -b];
      if (deg == 270) return [0, b];
      let tan = Math.tan((deg * Math.PI) / 180);
      let x = parseInt((a * b) / Math.sqrt(b * b + a * a * tan * tan));
      let y = parseInt(x * tan);
      x =
        (deg > 0 && deg < 90) || (deg > 270 && deg < 360)
          ? Math.abs(x)
          : -Math.abs(x);
      y = deg > 0 && deg < 180 ? -Math.abs(y) : Math.abs(y);
      return [x, y];
    },
  },
};
</script>

<style scoped lang="scss">
.earth-box {
  position: relative;
  transform: rotate(-24deg);
  cursor: pointer;
}

.earth-xin {
  position: absolute;
  background-image: url("~@/assets/images/earth.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  border-radius: 50%;
  overflow: hidden;
}

.earth-xin-top {
  width: 100%;
  height: 50%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  background-image: inherit;
  background-repeat: no-repeat;
  background-size: 100% auto;
}

.earth-quan {
  position: absolute;
  border: 1px solid rgba(151, 246, 252, 0.5);
  border-radius: 50%;
}

.earth-dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #97f6fc;
  box-shadow: 0 0 8px 8px rgba(151, 246, 252, 0.3);
  position: absolute;
  -webkit-transition: top 0.5s linear, left 0.5s linear;
  transition: top 0.5s linear, left 0.5s linear;
  cursor: pointer;
}

.earth-text {
  position: absolute;
  top: -20px;
  left: -10px;
  width: 50px;
  font-size: 10px;
  line-height: 1.4;
  text-align: center;
  color: #fff;
  white-space: nowrap;
  transform: rotate(24deg);
}

@media (min-width: 320px) and (max-width: 415px) {
  .earth-dot {
    width: 10px;
    height: 10px;
  }
  .earth-text {
    left: -10px;
  }
}
</style>